import React, { useState } from "react";
import { Section, SubSection } from "~components/text-helpers";
import Latex from "react-latex";
import { graphql, Link } from "gatsby";
import Img, { FixedObject, FluidObject } from "gatsby-image";
import { css } from "@emotion/react";
import { Table } from "antd";
import { UnityAPI } from "~components/api-data";

import {
  Code,
  Response,
  Attribute,
  IL,
  VSpace,
  SideBySideCode,
  Warning,
  APIMethod,
  APIReferenceiTHOR,
  Remark,
} from "~components/documentation";

export default function iTHORDocReference({ data, location }) {
  const [highlightedParam, setHighlightedParam] = useState("");
  return (
    <APIReferenceiTHOR columnKey="navigation" pageName="Navigation">
      <Section sectionTitle="Movement" emoji="elephant">
        <APIMethod
          location={location}
          methodKey="MoveAhead"
          parentAPI={UnityAPI.PhysicsAgent}
          setHighlightedParam={setHighlightedParam}
          highlightedParam={highlightedParam}
        />
      </Section>
      <Section sectionTitle="Agent Rotation" emoji="upside_down_face">
        <APIMethod
          location={location}
          methodKey="RotateRight"
          parentAPI={UnityAPI.PhysicsAgent}
          setHighlightedParam={setHighlightedParam}
          highlightedParam={highlightedParam}
        />
      </Section>
      <Section sectionTitle="Camera Rotation" emoji="movie_camera">
        <APIMethod
          location={location}
          methodKey="LookUp"
          parentAPI={UnityAPI.PhysicsAgent}
          setHighlightedParam={setHighlightedParam}
          highlightedParam={highlightedParam}
        />
      </Section>
      <Section sectionTitle="Crouch" emoji="person_in_lotus_position">
        <APIMethod
          location={location}
          methodKey="Crouch"
          parentAPI={UnityAPI.PhysicsAgent}
          setHighlightedParam={setHighlightedParam}
          highlightedParam={highlightedParam}
        />
      </Section>
      <Section sectionTitle="Stand" emoji="standing_person">
        <APIMethod
          location={location}
          methodKey="Stand"
          parentAPI={UnityAPI.PhysicsAgent}
          setHighlightedParam={setHighlightedParam}
          highlightedParam={highlightedParam}
        />
      </Section>
      <Section sectionTitle="Teleport" emoji="tophat">
        <APIMethod
          location={location}
          methodKey="Teleport"
          parentAPI={UnityAPI.PhysicsAgent}
          setHighlightedParam={setHighlightedParam}
          highlightedParam={highlightedParam}
        />
        <VSpace s="25px" />
        <APIMethod
          location={location}
          methodKey="TeleportFull"
          parentAPI={UnityAPI.PhysicsAgent}
          setHighlightedParam={setHighlightedParam}
          highlightedParam={highlightedParam}
        />
        <p
          css={css`
            margin-top: 10px;
          `}
        >
          It is often useful to randomize the position of the agent in the
          scene, before starting an episode. Here, we can use:
        </p>
        <ol>
          <li>
            <Link to="/ithor/documentation/environment-state/#sub-get-reachable-positions">
              GetReachablePositions
            </Link>
            , which does an optimized BFS over a grid spaced out by the
            initialized <IL>gridSize</IL>. The valid positions are then added
            and returned in a list.
          </li>
          <li>
            <IL>Teleport</IL>, which can take a given position, and transform
            the agent to that position.
          </li>
        </ol>
        <p>The process is illustrated below:</p>
        <SideBySideCode
          example={
            <>
              <Code
                lines={[
                  "positions = controller.step(",
                  '    action="GetReachablePositions"',
                  ').metadata["actionReturn"]',
                ]}
                caption="Step 1: Get the Positions"
              />
              <Response
                lines={[
                  "[",
                  "    dict(x=(...), y=(...), z=(...)),",
                  "    dict(x=(...), y=(...), z=(...)),",
                  "    dict(x=(...), y=(...), z=(...)),",
                  "    {...}",
                  "    dict(x=(...), y=(...), z=(...)),",
                  "]",
                ]}
              />
              <Code
                caption="Step 2: Teleport to a Position"
                lines={[
                  "import random",
                  "position = random.choice(positions)",
                  "controller.step(",
                  '    action="Teleport",',
                  "    position=position",
                  ")",
                ]}
              />
            </>
          }
          argsTitle="Get Reachable Positions Response"
        >
          <Attribute
            hashPrefix="get-reachable-positions"
            name="actionReturn"
            type="list[dict[str, float]]"
            description={
              <>
                <p>
                  A list of each position that the agent can be at, without
                  colliding into any other objects or going beyond the walls.
                </p>
                {/* <p>
                  <Latex>
                    The coordinate space for each RoboTHOR scene is shown below.
                    Notice that $$y$$ corresponds to the upward coordinate in 3D
                    space.
                  </Latex>
                </p> */}
                {/* <VSpace s="15px" /> */}
                {/* <Img fluid={data.dimensions.childImageSharp.fluid} /> */}
              </>
            }
          />
        </SideBySideCode>
      </Section>
      <Section sectionTitle="Done" emoji="sleeping">
        <p>
          The <IL>Done</IL> action nothing to the state of the environment. But,
          it returns a cleaned up event with respect to the metadata.
        </p>
        <Code lines={['controller.step(action="Done")']} />
        <p
          css={css`
            margin-top: 10px;
          `}
        >
          It is often used in the definition of a successful navigation task
          (see{" "}
          <a href="//arxiv.org/pdf/1807.06757.pdf" target="_blank">
            Anderson et al.
          </a>
          ), where the agent must call the done action to signal that it knows
          that it's done, rather than arbitrarily or biasedly guessing.
        </p>
        <Warning>
          <p>
            The <IL>Done</IL> action does literally nothing to the state of the
            environment. For instance, if the agent calls <IL>Done</IL> and then{" "}
            <IL>MoveAhead</IL>, the <IL>Done</IL> action will have no affect on
            preventing a <IL>MoveAhead</IL> action from executing.
          </p>
        </Warning>
        <p>It is often used to return a cleaned up version of the metadata.</p>
      </Section>
    </APIReferenceiTHOR>
  );
}
